@jasny-szary: #cccccc;
@maly-luk: 5px;
@maly-text: 0.9em;
@duzy-text: 1.5em;
@normal: 13px;

h2, h3, h4, h5 {
	border-bottom: 1px solid lighten(@jasny-szary, 12%);
}

.pages {
	.heading {
		font-weight: bold;
		border-bottom: 1px solid @jasny-szary;
		padding: 10px;
		margin-bottom: 5px; 
	}
	.btn {
		margin: 10px;
	}
	.level1 {
		border: 1px solid lighten(@jasny-szary, 10%);
		padding: 6px 2px 6px 2px;
		margin: 1px;
		-webkit-border-radius: @maly-luk;
		-moz-border-radius: @maly-luk;
		border-radius: @maly-luk;
		&:hover {
			background-color: lighten(@jasny-szary,17%);
		}
	}
	.level2 {
		border: 1px solid lighten(@jasny-szary, 10%);
		padding: 4px 1px 4px 1px;
		margin: 1px 1px 1px 25px;
		-webkit-border-radius: @maly-luk;
		-moz-border-radius: @maly-luk;
		border-radius: @maly-luk;
		color: darken(@jasny-szary, 10%);
		font-size: @maly-text;
		&:hover {
			background-color: lighten(@jasny-szary,17%);
		}
		.icon {
			margin: 0 0 0 -45px;
			padding-right: 20px;
			padding-top: -20px;
		}
	}
	.move {
		&:hover {
			cursor: move;
		}
	}
	img {
		margin: 10px;
		cursor: pointer;
		border: 1px solid @jasny-szary;
	}
	.meta {
		width: 100%;
		text-align: center;
		.btn {
			width: 90%;
		}
	}
	.glyphicon-unchecked, .glyphicon-star-empty {
		color: @jasny-szary;
	}
	.delfoto {
		font-size: @duzy-text*1.5;
		span {
		left: 250px;
		top: 25px;
			}
	}
}

.gallery {
	h1 {
		color: @jasny-szary;
	}
	.gal {
		display: inline-block;
		.glyphicon-trash {
			font-size: 1.5em;
			top: 40px;
			left: 160px;
		}
		.glyphicon-pencil {
			font-size: 1.5em;
			top: 180px;
			left: 135px;
		}
	}
}
.noactive {
	color: @jasny-szary;
	font-style: italic;
}
.gray {
	color: @jasny-szary;
}

.del {
	text-decoration: line-through;
}

.elementy {
		border: 1px solid lighten(@jasny-szary, 10%);
		padding: 4px 2px 4px 2px;
		margin: 1px;
		-webkit-border-radius: @maly-luk;
		-moz-border-radius: @maly-luk;
		border-radius: @maly-luk;
		&:hover {
			background-color: lighten(@jasny-szary,17%);
		}
	}
.important {
	color: red;
	font-style: italic;
}


//robocze:
.red{ border: 1px solid red;}